import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useSectorsList() {
  const toast = useToast();
  const records = ref(null);
  const isBusy = ref(false);

  // Table Handler
  const columns = [
    { key: "name", sortable: true },
    { key: "units", sortable: true },
    { key: "packaging" },
    { key: 'pricing', label: 'Pricing Strategy', },
    { key: 'prices', label: 'Prices', },
    "actions"
  ];

  const from = ref(0);
  const to = ref(0);
  const meta = ref({ from: 0, to: 0, total: 0 });
  const perPage = ref(10);
  const totalRecords = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("name");
  const isSortDirDesc = ref(true);
  const season_id = ref(null);

  const refetch = () => {
    records.value.refresh();
  };

  watch([season_id, currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
    refetch();
  });

  const fetch = (ctx, callback) => {
    isBusy.value = true;
    store
      .dispatch("cromis-input/list", {
        season_id: season_id.value,
        query: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        isBusy.value = false;
        const { inputs, meta } = response.data;

        meta.value = meta;

        callback(inputs);
        totalRecords.value = meta.value.total;
        from.value = meta.value.from;
        to.value = meta.value.to;
      })
      .catch((error) => {
        isBusy.value = false;
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching sales list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveGrowerTypeVariant = (society) => {
    if (society !== null) return "success";
    return "primary";
  };

  const resolveStatusVariant = (status) => {
    if (!status) return "info";
    return "success";
  };

  return {
    fetch,
    columns,
    perPage,
    currentPage,
    totalRecords,
    from,
    to,
    meta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    records,
    resolveGrowerTypeVariant,
    resolveStatusVariant,
    refetch,
    // Extra Filters
    season_id,
    isBusy,
  };
}
